import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarLink,
    SidebarWrapper,
    SidebarMenu
} from './SideBarElements';

const Sidebar = ( { isOpen, toggle} ) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="services" activeClass="active" spy={true} smooth={true} offset={-70} onClick={toggle}>Services</SidebarLink>
                    <SidebarLink to="about" spy={true} smooth={true} offset={-70} onClick={toggle}>About</SidebarLink>
                    <SidebarLink to="why" spy={true} smooth={true} offset={-70} onClick={toggle}>Why Us?</SidebarLink>
                    <SidebarLink to="contact" spy={true} smooth={true} offset={-70} onClick={toggle}>Contact</SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;