import { 
    Section,
    SectionAlt,
    Grid,
    SectionTitle,
    SectionTitleAlt,
    EvenRow,
    TextBlock,
    TextBlockHeader,
    TextBlockText,
    ImageBlock
} from "../../Utility/PageSectionElements";

import { ReactComponent as ExpertsImage } from "../../Resources/experts_orange.svg"
import { ReactComponent as SecureImage } from "../../Resources/secure_orange.svg"
import { ReactComponent as CustomerServiceImage } from "../../Resources/customer_service_orange.svg"
import { ReactComponent as SingleContactImage } from "../../Resources/single_contact_orange.svg"
import { ReactComponent as WarehouseImage } from "../../Resources/warehouse_orange.svg"



const Perks = () => {
    return ( 
        <div id = "why">
            <Section theme = 'secondary'>
                <SectionTitle theme = 'secondary'>
                    A COMPLETE LOGISTICS SOLUTION YOU CAN TRUST!
                </SectionTitle>
                <SectionTitleAlt>
                    Efficiency And Reliability In Everything We Do
                </SectionTitleAlt>
            </Section>
            <SectionAlt>
                <Grid>
                    <EvenRow>
                        <ImageBlock order = '2'>
                            <ExpertsImage></ExpertsImage>
                        </ImageBlock>
                        <TextBlock textAlign = 'left' alignItems = 'right' order = '1'>
                            <TextBlockHeader>Experienced Providers</TextBlockHeader>
                            <TextBlockText>Our managers have over 30 years of experience in logistics and freight forwarding.</TextBlockText>
                        </TextBlock>
                    </EvenRow>
                </Grid>
            </SectionAlt>
            <Section>
                <Grid>
                    <EvenRow>
                        <TextBlock textAlign = 'left' alignItems = 'left'>
                            <TextBlockHeader>Single Point Of Contact</TextBlockHeader>
                            <TextBlockText>We care about our partners and for every account all services are provided by a single contact.</TextBlockText>
                        </TextBlock>
                        <ImageBlock>
                            <SingleContactImage></SingleContactImage>
                        </ImageBlock>
                    </EvenRow>
                </Grid>
            </Section>
            <SectionAlt>
                <Grid>
                    <EvenRow>
                        <ImageBlock order = '2'>
                            <WarehouseImage></WarehouseImage>
                        </ImageBlock>
                        <TextBlock textAlign = 'left' alignItems = 'right' order = '1'>
                            <TextBlockHeader>Full-Service Warehouse</TextBlockHeader>
                            <TextBlockText>Our warehouse has 5000sqft of space and a clear ceiling height of 20 feet. It has a ground level door with a height of 14 feet and 2 Dock-high doors with a height of 10 feet. We utilize both Rack and Floor(Bulk) Storage.</TextBlockText>
                        </TextBlock>
                    </EvenRow>
                </Grid>
            </SectionAlt>
            <Section>
                <Grid>
                    <EvenRow>
                        <TextBlock textAlign = 'left' alignItems = 'left'>
                            <TextBlockHeader>Secure Facility</TextBlockHeader>
                            <TextBlockText>Our warehouse is fully equipped with Fire alarms and a Sprinkler System, a 24/7 Monitered Alarm System, Sound and Motion Sensors, and a Video Recording System.</TextBlockText>
                        </TextBlock>
                        <ImageBlock>
                            <SecureImage></SecureImage>
                        </ImageBlock>
                    </EvenRow>
                </Grid>
            </Section>
            <SectionAlt>
                <Grid>
                    <EvenRow>
                        <ImageBlock order = '2'>
                            <CustomerServiceImage></CustomerServiceImage>
                        </ImageBlock>
                        <TextBlock textAlign = 'left' alignItems = 'right' order = '1'>
                            <TextBlockHeader>Excellent Customer Service</TextBlockHeader>
                            <TextBlockText>At Access International Services our friendly and knowledgable staff strive to satisfy all your logistical needs because we care.</TextBlockText>
                        </TextBlock>
                    </EvenRow>
                </Grid>
            </SectionAlt>
        </div>
     );
}
 
export default Perks;