import {
    AboutContainer,
    AboutSubHeader,
    AboutP,
    AboutTitle
} from './AboutElements';

const About = () => {
    
    return (
        <AboutContainer id = "about">
            <AboutTitle>ABOUT US</AboutTitle>
            <AboutSubHeader>Our Origin</AboutSubHeader>
            <AboutP>
                We are a freight forwarding company that provides consistent, dependable and professional international logistics and transportation services to the shipping community. Our services are integrated solutions that include all the arrangements for exports and imports to all parts of the globe with a specialization in Latin America. We handle customs formalities and delivery, giving you a full service, which includes efficiency, reliability, timing and pricing. Access International Services Corp. was founded in 2005 by Jairo Rivas, Gustavo Lopez and John Benjumea, three entrepreneurs with more than 30 years of experience in the logistics business. They wanted to join their knowledge and efforts to offer better service to the shipping community. Service that fulfills and integrates all their needs.
            </AboutP>
            <AboutSubHeader>Our Mission</AboutSubHeader>
            <AboutP>
                We are Access International Services Corp, a third party logistics providers, looking to ensure customer satisfaction by offering high quality, reliable, punctual and low cost services. Our goal is to offer a complete menu of logistics services including Air, Ocean, and Inland freight, warehousing, packaging and more, backed up by the experience of our staff, to give you an excellent international and domestic logistics solution.
            </AboutP>
            <AboutSubHeader>Our Vision</AboutSubHeader>
            <AboutP>
                Access international services corporation aims to be the most reliable freight forwarder company in terms of trust, efficiency, customer satisfaction and fastest response to our customer needs. To grow in our business and become your best choice for logistics we take personal care to each of our costumers, building long term relationships based on trust and respect.
            </AboutP>
        </AboutContainer>
    );
};

export default About;