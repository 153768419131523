import styled from "styled-components";
import { SectionTitle } from "../../Utility/SectionTitleElements";

export const AboutContainer = styled.div`
    background-color: rgb(233,233,233);
    padding: 32px;
    padding-left: 16px;
    padding-right: 16px;
`

export const AboutTitle = styled(SectionTitle)`
    color: rgb(11, 43, 117);
`

export const AboutSubHeader = styled.h4`
    color: rgb(28,28,28);
    text-align: left;
`

export const AboutP = styled.p`
    margin-top:32px;
    margin-bottom:32px;
    color: rgb(28,28,28); 
`