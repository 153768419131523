import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    MobileIconButton
} from './NavBarElements';

import { animateScroll } from 'react-scroll'

const Navbar = ({ toggle }) => {

    var scrollToTop = () =>  {
        animateScroll.scrollToTop();
    };

    return (
        <Nav>
            <NavbarContainer>
                <NavLogo onClick = {scrollToTop}>AISLOGISTICS</NavLogo>
                <MobileIcon onClick={toggle}>
                    <MobileIconButton></MobileIconButton>
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to="services" activeClass="active" spy={true} smooth={true} offset={-70}>Services</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="about" spy={true} smooth={true} offset={-70}>About</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="why" spy={true} smooth={true} offset={-70}>Why Us?</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="contact" spy={true} smooth={true} offset={-70}>Contact</NavLinks>
                    </NavItem>
                </NavMenu>
            </NavbarContainer>
        </Nav>
    );
};

export default Navbar