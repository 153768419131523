import styled from 'styled-components';
import {theme} from "../theme.js"

/**
 * This is a collection of styled components that are frequently used.
 */

/**
 * Section
 * 
 * Each major section of a page that will be using these components should be defined in a Section or SectionAlt
 */
export const Section = styled.div `
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: ${props => props.theme === 'secondary' ? theme.secondary.color1 : theme.primary.color1};
`
export const SectionAlt = styled.div `
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: ${props => props.theme === 'secondary' ? theme.secondary.color2 : theme.primary.color2};
`

/**
 * Grid
 * 
 * Should be placed inside of a section element if more than one block component will be used 
 */
export const Grid = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    padding-left: 16px;
    padding-right: 16px;
`

/**
 * SectionTitle
 * 
 * Title to be used in Section component 
 */
export const SectionTitle = styled.h1`
    color: ${props => props.theme === 'secondary' ? theme.secondary.header1 : theme.primary.header1};
    text-align: center;
    margin: 32px;
    font-size: 42px;
    @media screen and (max-width: 1000px) {
        font-size: 28px;
    }
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`
export const SectionTitleAlt = styled.h1`
    color: ${props => props.theme === 'secondary' ? theme.secondary.header2 : theme.primary.header2};
    text-align: center;
    margin: 32px; 
    font-size: 42px;
    @media screen and (max-width: 1000px) {
        font-size: 28px;
    }
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`

/**
 * Row
 * 
 * Must be used inside Grid component. When we are using blocks. Internal Use Only.
 * 
 * EvenRow: Use with even number of blocks
 * OddRow: Use with 3 blocks
 */
const Row = styled.div `
    margin: 0 auto;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: 32px;
`
export const EvenRow = styled(Row) `
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`
export const OddRow = styled(Row) `
    grid-template-columns: 1fr 1fr 1fr;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

/**
 * Block. For Internal Use Only.
 * Several block types defined below
 */
const Block= styled.div `
    display: flex;
    order: ${props => props.order}
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${props => props.alignItems || 'center'};
    text-align: ${props => props.textAlign || 'center'};
    margin-bottom: 32px;
    min-height: 1px;
    @media screen and (max-width: 768px) {
        order: ${props=> props.order};
    }
`

/**
 * BLOCKS
 */


/**
 * Card: Will have a Font-Awesome Logo, A Header, and Text
 */
export const Card = styled(Block)`
    color: ${ props => props.theme === 'secondary' ? theme.secondary.text : theme.primary.text};
`
export const CardLogo = styled.div`
    font-size: 100px;
    margin-bottom:16px;
`
export const CardHeader = styled.h4`
    margin-bottom:16px;
`
export const CardText = styled.p`
`

/**
 * TextBlock: A header and text. Either right or left.
 */
 export const TextBlock = styled(Block) `
    color: ${props => props.theme === 'secondary' ? theme.secondary.text : theme.primary.text};
    margin: 32px;
 `
 export const TextBlockHeader = styled.h2`
    font-size: 42px;
    @media screen and (max-width: 1000px) {
        font-size: 28px;
    }
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`
export const TextBlockText = styled.p`
    font-size: 28px;
    @media screen and (max-width: 1000px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`

/**
 * Image: An image of users choice. Must contain in an img element.
 */
export const ImageBlock = styled(Block) `
    width: 100%;
    height: 100%;
    padding: 32px;
    object-fit: contain;
`

