import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {useState} from 'react';
import Navbar from './Components/Navbar/Navbar'
import Home from './Pages/Home/Home'
import NotFound from './Pages/NotFound/NotFound';
import SideBar from './Components/Navbar/SideBar';

function App() {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
      setIsOpen(!isOpen)
  }

  return (
    <Router>
      <SideBar isOpen={isOpen} toggle={toggle}></SideBar>
      <Navbar toggle={toggle}></Navbar>
      <div className="content">
        <Switch>
          <Route exact path="/">
            <Home></Home>
          </Route>
          <Route path="/*">
            <NotFound></NotFound>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
