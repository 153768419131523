import styled from "styled-components";
import { Grid, Row, Col } from '../../Utility/Grid' 
import { SectionTitle } from "../../Utility/SectionTitleElements";

export const ServicesContainer = styled(Grid)`
    background-color: rgb(233,233,233);
`

export const ServiceTitle = styled(SectionTitle)`
    color: rgb(11, 43, 117);
`

export const ServicesRow = styled(Row)`
    grid-template-columns: 1fr 1fr 1fr;
    // @media screen and (max-width: 1000px) {
    //     grid-template-columns: 1fr 1fr;
    // }
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

export const ServicesCard = styled(Col)`
    color: rgb(28,28,28);
`

export const ServicesCardLogo = styled.div`
    font-size: 100px;
    color: rgb(28,28,28);
    margin-bottom:16px;
`

export const ServicesCardH4 = styled.h4`
    margin-bottom:16px;
`