import { useState } from 'react';
import { Button } from '../../Utility/ButtonElement';
import {
    HeroContainer,
    HeroBg,
    PhotoBg,
    HeroBtnWrapper,
    HeroContent,
    HeroH1,
    HeroP,
    ArrowForward,
    ArrowRight
} from './HeroSectionElements';

const HeroSection = () => {
    const [hover, setHover] = useState(false)
    
    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer>
            <HeroBg>
                <PhotoBg></PhotoBg>
            </HeroBg> 
            <HeroContent>
                <HeroH1>LOGISTICS BEYOND EXPECTATIONS</HeroH1>
                <HeroP>
                    We'll Take Care Of All Your Forwarding Needs. Leave Your Shipment To Us!
                </HeroP>
                <HeroBtnWrapper>
                    <Button 
                    to='services' 
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover}
                    spy={true} 
                    smooth={true} 
                    offset={-70}
                    >
                        Learn More {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;