export const theme = {
    primary: {
        color1: 'rgb(240,240,240)',
        color2: 'rgb(230,230,230)',
        header1: 'rgb(50, 110, 140)',
        header2: 'rgb(210, 180, 120)',
        text: 'rgb(28,28,28)',
    },
    secondary: {
        color1: 'rgb(50, 110, 140)',
        color2: 'rgb(30,70,100)',
        header1: 'rgb(240,240,240)',
        header2: 'rgb(210, 180, 120)',
        text: 'rgb(230,230,230)',
    }
}