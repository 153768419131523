import styled from 'styled-components';
import { SectionTitle } from '../../Utility/SectionTitleElements';

export const ContactContainer = styled.div`
    background-color: rgb(233,233,233);
    padding: 32px;
    padding-left: 16px;
    padding-right: 16px;
`

export const ContactTitle = styled(SectionTitle)`
    color: rgb(11, 43, 117);
`

export const ContactSubHeader = styled.h3`
    color: #cfb078;
    text-align: left;
`

export const ContactP = styled.p`
    margin-top:16px;
    margin-bottom:16px;
    color: rgb(28,28,28); 
`

export const ContactMap = styled.iframe`
    border-style: none;
    width: 100%;
    height: 100vh;
    padding: 16px;
`

export const ContactInfo = styled.div`
    color: rgb(28,28,28);
    margin-top: 64px;
    margin-bottom: 64px;
`
