import { Link } from "react-scroll";
import styled from "styled-components";

//TODO: I wanna move the main colors of the project to somewhere more global. So we can easily change the color scheme of the whole project
export const Button = styled(Link)`
    border-radius: 50px;
    background: #cfb078;
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: rgb(28,28,28);
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    &:hover {
        transition: all 0.2s ease-in-out;
        background: rgb(233,233,233);
    }
`