import {
    FooterContainer,
    FooterText,
} from './FooterElements'

const Footer = () => {
    return ( 
        <FooterContainer>
            <FooterText>
                © 2021 Access International Services Corp. All Rights Reserved.
            </FooterText>
        </FooterContainer>
     );
}
 
export default Footer;