import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faWarehouse, faShieldAlt, faBox, faPassport } from '@fortawesome/free-solid-svg-icons'

import {
    MoreServicesContainer,
    MoreServicesRow,
    MoreServicesCard,
    MoreServicesCardLogo,
    MoreServicesCardH4,
    MoreServiceTitle
} from './MoreServicesElement'

const MoreServices = () => {
    return ( 
        <MoreServicesContainer>
            <MoreServiceTitle>ADDITIONAL SERVICES</MoreServiceTitle>
            <MoreServicesRow>
                <MoreServicesCard>
                    <MoreServicesCardLogo>
                        <FontAwesomeIcon icon={faWarehouse} />
                    </MoreServicesCardLogo>
                    <MoreServicesCardH4>WAREHOUSING</MoreServicesCardH4>
                    <p>
                        With our warehousing services, your import and export goods are in good hands. We store your goods until you need them. At Access International Services we can efficiently satisfy your specific freight storage requirements. Our full service facility gives our customers the coverage and dependability they deserve and expect.
                    </p>
                </MoreServicesCard>
                <MoreServicesCard>
                    <MoreServicesCardLogo>
                        <FontAwesomeIcon icon={faShieldAlt} />
                    </MoreServicesCardLogo>                    
                    <MoreServicesCardH4>CARGO INSURANCE</MoreServicesCardH4>
                    <p>
                        Access International Services Corp can tailor an insurance program to fit your specific needs. Our experience in insurance policies and handling claims allows you to rest easy. Your cargo will be safe with us!
                    </p>
                </MoreServicesCard>
                <MoreServicesCard>
                    <MoreServicesCardLogo>
                        <FontAwesomeIcon icon={faBox} />
                    </MoreServicesCardLogo>                    
                    <MoreServicesCardH4>PACKAGING AND CRATING</MoreServicesCardH4>
                    <p>
                        We manufacture a wide variety of custom crates, boxes and skids for all kinds of freight, even for containerized and break-bulk shipments. We handle your cargo with great care using a wide range of packaging materials to protect your shipment.
                    </p>
                </MoreServicesCard>
                <MoreServicesCard>
                    <MoreServicesCardLogo>
                        <FontAwesomeIcon icon={faPassport} />
                    </MoreServicesCardLogo>
                    <MoreServicesCardH4>CUSTOMS BROKERING</MoreServicesCardH4>
                    <p>
                        We provide complete U.S. Customs clearance services on all permitted commodities. Whether a standard entry or a special project, we will provide you with support to get your cargo through customs and to your destination as quickly as possible.
                    </p>
                </MoreServicesCard>
            </MoreServicesRow>
        </MoreServicesContainer>
     );
}
 
export default MoreServices;