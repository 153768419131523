import styled from "styled-components";

export const FooterContainer = styled.div`
background-color: rgb(50, 110, 140);
width: 100%;
padding: 16px;
`

export const FooterText = styled.p`
color: rgb(233,233,233);
margin: 16px;
`