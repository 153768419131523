import About from '../../Components/About/About';
import Contact from '../../Components/Contact/Contact';
import Footer from '../../Components/Footer/Footer';
import HeroSection from '../../Components/HeroSection/HeroSection';
import MoreServices from '../../Components/MoreServices/MoreServices';
import Perks from '../../Components/Perks/Perks';
import Services from '../../Components/Services/Services';

const Home = () => {

    return ( 
        <div>
            <HeroSection></HeroSection>
            <Services></Services>
            <MoreServices></MoreServices>
            <About></About>
            <Perks></Perks>
            <Contact></Contact>
            <Footer></Footer>
        </div>
     );
}
 
export default Home;