import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShip, faPlane, faTruck } from '@fortawesome/free-solid-svg-icons'

import {
    ServicesContainer,
    ServicesRow,
    ServicesCard,
    ServicesCardLogo,
    ServicesCardH4,
    ServiceTitle
} from './ServicesElement'

const Services = () => {
    return ( 
        <ServicesContainer  id="services">
            <ServiceTitle>FREIGHT FORWARDING SERVICES</ServiceTitle>
            <ServicesRow>
                <ServicesCard>
                    <ServicesCardLogo>
                        <FontAwesomeIcon icon={faShip} />
                    </ServicesCardLogo>
                    <ServicesCardH4>OCEAN FREIGHT</ServicesCardH4>
                    <p>Access International Services provides 
                        ocean freight import and export services 
                        to major ports overseas. We personally 
                        ensure that all documentation is complete 
                        and accurate. Once your shipment arrives, 
                        appropriate customs clearance procedures 
                        are arranged to ensure a smooth shipment 
                        and your satisfaction.
                    </p>
                </ServicesCard>
                <ServicesCard>
                    <ServicesCardLogo>
                        <FontAwesomeIcon icon={faPlane} />
                    </ServicesCardLogo>                    
                    <ServicesCardH4>AIR FREIGHT</ServicesCardH4>
                    <p>
                        We provide Air freight service to worldwide
                        destinations. Whether it's a small box small
                        box or heavy equipment that requires expedited
                        service, Access International Services is the
                        solution. Each air shipment is handled 
                        individually and managed by professionals. 
                        Whatever the case, we work with you to get the
                        job done to your satisfaction.
                    </p>
                </ServicesCard>
                <ServicesCard>
                    <ServicesCardLogo>
                        <FontAwesomeIcon icon={faTruck} />
                    </ServicesCardLogo>                    
                    <ServicesCardH4>INLAND FREIGHT</ServicesCardH4>
                    <p>
                        We provide punctual Door-to-Door 
                        transportation within the USA at a low cost.
                        A Bill of Lading or Pick-up/Delivery Order is
                        issued on every inland freight which provides
                        you with a reference number that you can use
                        to always keep track of your shipment online.
                    </p>
                </ServicesCard>
            </ServicesRow>
        </ServicesContainer>
     );
}
 
export default Services;