import {
    ContactContainer,
    ContactTitle,
    ContactMap,
    ContactInfo,
    ContactSubHeader,
    ContactP
} from './ContactElements';

const Contact = () => {
    return ( 
        <ContactContainer id = "contact">
            <ContactTitle>CONTACT US</ContactTitle>
            <ContactInfo>
                <ContactSubHeader>Contact Information:</ContactSubHeader>
                <ContactP><strong>Contacts:</strong> Jairo Rivas and Gustavo López</ContactP>
                <ContactP><strong>Phone:</strong> (305) 639-6762</ContactP>
                <ContactP><strong>Fax:</strong> (305) 639-6764</ContactP>
                <ContactP><strong>Email:</strong> info@aislogistics.com</ContactP>
                <ContactP><strong>Address:</strong> 8073 NW 67TH STREET MIAMI, FLORIDA 33166</ContactP>
            </ContactInfo>
            <ContactSubHeader>Location:</ContactSubHeader>
            <ContactMap src="https://embed.waze.com/iframe?zoom=13&lat=25.834787386194034&lon=-80.32844273300648&pin=1"></ContactMap>
        </ContactContainer>

     );
}
 
export default Contact;